.Enquery {
  /* padding: 3rem 0rem; */
  width: 100%;
}

/* .Enquery-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
} */

.enquery-box {
  display: flex;
}

.enquery-left {
  /* padding: 2rem; */
  width: 50%;
}

.enquery-left img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.enquery-right {
  width: 50%;
}

.enquery-form-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.enquery-form-box .enquery-form {
  width: 70%;
}
.enquery-form-box .enquery-form h4 {
  font-size: 18px;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  /* .Enquery-main {
    max-width: 1140px;
  } */
}

@media only screen and (max-width: 680px) {
    .enquery-box {
      flex-wrap: wrap;
    }
    .enquery-left {
      width: 100%;
    }
    .enquery-right {
      width: 100%;
      padding: 2rem 1rem;
    }

    .enquery-form-box .enquery-form {
        width: 100%;
    }
  }
