.HomePlan {
  padding: 3rem 0rem;
  width: 100%;
}

.HomePlan-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.HomePlan-main h2 {
  font-size: 30px;
  margin-bottom: 20px;
}
.HomePlan-main h2:after {
  content: "";
  margin-top: 2px;
  display: flex;
  height: 2px;
  width: 40%;
  border-radius: 50px;
  background-color: goldenrod;
}

.homeplan-box {
  display: flex;
  gap: 5rem;
  margin-bottom: 3rem;
}

.homeplan-left {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homeplan-right {
  width: 40%;
}

.homeplan-right img {
  width: 100%;
  border-radius: 8px;
  /* padding: 10px; */
}

@media (min-width: 1200px) {
  .HomePlan-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 680px) {
  .homeplan-box {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .homeplan-left {
    width: 100%;
    order: 2;
    text-align: center;
    /* margin-bottom: 20px; */
  }
  .homeplan-right {
    width: 100%;
    order: 1;
  }

  .HomePlan-main h2 {
    font-size: 20px;
  }
  .HomePlan-main p {
    margin: 0;
  }
}
