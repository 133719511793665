.navBar {
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}

.nav-menu {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 15px;
}
.navbar-items {
  width: 100%;
  /* border-top: 1px solid #9a6844; */
}

.company-logo img {
  width: 100%;
  object-fit: cover;
  padding: 15px;
  max-height: 130px;
}

.navbar {
  padding: 0;
}

.container-fluid {
  padding: 0;
}

.navbar-collapse {
  justify-content: center;
}

/* animated button */

.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #283664;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

.navbar-toggler.collapsed .top-bar {
  margin-top: -18px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 18px;
  transform: rotate(0deg);
}

.nav-action {
  display: flex;
  align-items: center;
}

.nav-button {
  /* border: 2px solid #283664; */
  /* margin-right: 20px; */
  padding: 10px;
}

.navbar-light .navbar-toggler {
  border: none;
  box-shadow: none;
}

.nav-menu ul li {
  position: relative;
}

.nav-menu ul li a {
  padding: 10px 12px !important;
  font-size: 1rem;
  text-transform: capitalize;
}

.navbar-light .navbar-nav .nav-link {
  color: #000; /* Default text color */
  border-bottom: none; /* No underline by default */
}

.navbar-light .navbar-nav .nav-link.active {
  color: #283664; /* Blue color */
  border-bottom: 2px solid #283664; /* Underline */
}

.call-button p {
  margin: 0;
  display: inline;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  text-decoration: none;
  border-radius: 5px;
  color: black;
  width: 130px;
  padding: 10px 20px;
  font-weight: 500;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #283664;
  border-radius: 5px;
  margin-left: 20px;
}

.call-button p a {
  text-decoration: none;
  color: white;
}
.call-button p a svg {
  font-size: 18px;
  margin-right: 10px;
}
/* .call-button p:hover {
    background-color: #5277bc;
  } */

.navbar-nav .dropdown-menu {
  background-color: #383535;
  border-top: 1px solid red;
  border-radius: 0px;
  top: 97%;
  padding: 0;
}

.nav-menu ul li a:hover {
  color: black !important;
}

.dropdown-menu {
  display: none;
}

#luxuryDropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-toggle::after {
  display: none !important;
}

/* fixed icons */
.fix-icon {
  position: fixed;
  bottom: 2%;
  right: 2%;
}

.fix-icon a {
  color: white;
  cursor: pointer;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366;
  margin-bottom: 8px;
  z-index: 1;
}

.fix-icon .shine {
  position: absolute;
  bottom: 15%;
  right: 0%;
  content: "";
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #25d366;
  animation: shine 2s infinite;
  z-index: -1;
}

.fix-icon a svg {
  font-size: 25px;
  animation: whatsapp 2s infinite;
}

.fix-icon-call {
  position: fixed;
  bottom: 10%;
  right: 2%;
}
.fix-icon-call a {
  color: white;
  cursor: pointer;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffb000;
  margin-bottom: 8px;
  z-index: 1;
}
.fix-icon-call .shine {
  position: absolute;
  bottom: 15%;
  right: 0%;
  content: "";
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ffb000;
  animation: shine 2s infinite;
  z-index: -1;
}
.fix-icon-call a svg {
  font-size: 25px;
}

.fix-icon-enquery {
  position: fixed;
  bottom: 50%;
  right: -4%;
  rotate: -90deg;
}

.fix-icon-enquery p {
  background-color: #283664;
  padding: 12px 24px;
  color: white;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  text-align: right;
  transition: 0.3s ease-in-out;
}
.fix-icon-enquery p:hover {
  background-color: #ffb000;
  color: black;
}

@keyframes whatsapp {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.1);
    opacity: 1.1;
  }
}
@keyframes shine {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

@media screen and (min-width: 1200px) {
  .nav-menu {
    max-width: 1140px;
  }
}

@media screen and (min-width: 990px) {
  .navbar-nav .nav-item .dropdown-menu.mega-menu::before {
    content: "";
    border-bottom: 13px solid white;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    right: initial;
    display: inline-block;
    position: absolute;
    top: -12px;
    left: 50%;
    margin-left: 0;
    transform: translate(-50%);
  }

  .nav-button {
    display: none;
  }
  .fix-icon-enquery {
    right: -4%;
  }
}

@media only screen and (max-width: 990px) {
  .nav-action {
    flex-flow: wrap;
  }

  .nav-menu ul li {
    margin: 0px 10px;
    position: relative;
    border-bottom: 1px solid #bbb;
  }

  .nav-action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px;
    padding: 1rem 0;
  }
  .fix-icon-enquery {
    right: -5%;
  }
}
@media only screen and (max-width: 540px) {
  .company-logo img {
    max-height: 80px;
  }

  .company-logo img {
    padding: 15px 0px;
  }
  .fix-icon-enquery {
    right: -15%;
  }
}
