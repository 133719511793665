.aboutUs {
  padding: 3rem 0rem;
  width: 100%;
  background-color: #fafafa;
}

.about-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.about-items {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.about-left {
  width: 50%;
}

.about-title {
  text-align: center;
}
.about-title h1 {
  color: #212121;
  font-size: 36px;
  padding-bottom: 10px;
  font-weight: bold;
  margin: 0;
}
.about-title p {
  color: #283664;
  font-size: 15px;
  margin: 0;
}
.about-title span {
  color: rgb(68, 68, 68);
  font-size: 15px;
  line-height: 35px;
  font-weight: bold;
}

.about-title h4 {
  color: #283664;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 10px;
}
.about-title h4 span {
  color: #283664;
  font-size: 35px;
  font-weight: bold;
  margin: 0;
}

.about-detail h3 {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  color: black;
  padding-bottom: 20px;
}
.about-detail p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  color: black;
  padding-bottom: 20px;
}

.about-right {
  width: 50%;
}

.about-right img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  /* rotate: 90deg; */
}

.about-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

/* .about-button a p {
  margin: 0;
  font-size: 20px;
  padding: 15px 25px;
  border-radius: 8px;
  background-color: #283664;
  transition: .3s ease-in-out;
} */
/* .about-button a p:hover {
  background-color: #ffb000;
  color: black;
} */

.about-button a {
  text-decoration: none;
  color: white;
  font-size: 14px;
}

.about-button a {
  padding: 10px;
  background: #283664;
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  overflow: hidden;
  border-radius: 8px;
}

.about-button a p {
  z-index: 1;
  color: white;
  margin: 0;
  font-size: 20px;
}

.about-button a::before {
  content: "";
  position: absolute;
  width: 100%;
  background-image: linear-gradient(180deg, #ffb000, #ffb000);
  height: 50%;
  animation: rotBGimg 3s linear infinite;
  transition: all 0.2s linear;
}

@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.about-button a::after {
  content: "";
  position: absolute;
  background: #283664;
  inset: 5px;
  border-radius: 8px;
}
.about-button a:hover:before {
  background-image: linear-gradient(180deg, gold, gold);
  animation: rotBGimg 3.5s linear infinite;
}

@media (min-width: 1200px) {
  .about-main {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 990px) {
  .about-main {
    max-width: 720px;
  }
}
@media only screen and (max-width: 680px) {
  .about-items {
    flex-wrap: wrap;
  }

  .about-left,
  .about-right {
    width: 100%;
  }

  .about-title h4 {
    margin-top: 20px;
    font-size: 28px;
    line-height: 40px;
  }

  .about-right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-main {
    max-width: 520px;
  }

  .about-button a {
    width: 100%;
    text-align: center;
  }
}
